import React from 'react';
import { PortableText } from '@portabletext/react';
import { useQuery } from 'react-query';
import { fetchPois } from 'data/fetchers';
import PoiCard from 'components/cards/poi/PoiCard';
import { IPoiCollectionBlock } from '../content/PoiCollectionBlock';
import { getPoiAreas } from 'utils/POIHelpers';

const FeaturedPoiCollection = ({ collection: block }: { collection: IPoiCollectionBlock }) => {
    const poiIds = block?.pois?.map((poi) => poi.id);
    const filters = poiIds.map((id) => ({ key: 'id', value: id }));
    const includes = ['areas', 'type', 'thumbs', 'attributes.attribute'];
    const { data: pois } = useQuery(['poi_by_ids', ...poiIds], () => fetchPois({ filters, includes }));

    return (
        <div className="py-12">
            <h2 className="font-bold text-brand mb-4 text-center">{block.title}</h2>
            <div className="max-w-lg text-center mx-auto mb-4">
                <PortableText value={block.description} />
            </div>
            <div className="mt-8 grid grid-cols-1 sm:grid-cols-3 xl:grid-cols-5 gap-6">
                {pois?.data.map((poi) => (
                    <PoiCard
                        key={poi.id}
                        prefetch={false}
                        images={poi.thumbs}
                        title={poi.name}
                        link={`/ideja/${poi.slug}`}
                        location={`${getPoiAreas(poi).municipality?.name}, ${getPoiAreas(poi).region?.name}`}
                        poiType={poi.type}
                        attributes={poi.attributes}
                        featured={poi.id === 149}
                    />
                ))}
            </div>
        </div>
    );
};

export default FeaturedPoiCollection;
