function DoubleCurvesSvg(props) {
    return (
        <svg width="100%" height={19} viewBox="0 0 732 19" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M113.784 15.783c26.932 1.007 53.899 2.013 80.948 2.194 13.419.088 26.85-.03 40.246-.455 13.419-.425 26.733-1.236 40.058-2.018 13.197-.776 26.417-1.436 39.731-1.778 13.477-.347 26.99-.45 40.503-.484 26.897-.058 53.84.176 80.69-.61 25.41-.743 51.405-2.585 76.733-.557 3.091.249 6.136.557 9.157.923 2.459.298 2.904-1.866.796-2.12-24.812-3-50.796-1.72-76.077-.797-26.826.977-53.688.953-80.585.958-26.873.005-53.712.215-80.409 1.666-13.314.723-26.569 1.617-39.906 2.213-13.478.601-27.026.87-40.574.894-27.189.054-54.32-.845-81.381-1.842-3.349-.122-6.698-.249-10.047-.376-2.26-.083-2.236 2.101.117 2.189zM4.172 6.783c47.784 1.006 95.631 2.013 143.624 2.194 23.809.088 47.639-.03 71.406-.455 23.81-.425 47.432-1.236 71.075-2.018 23.414-.776 46.87-1.436 70.492-1.778 23.913-.347 47.889-.45 71.864-.484 47.722-.058 95.528.176 143.167-.61 45.084-.743 91.206-2.585 136.144-.558 5.485.25 10.887.557 16.247.924 4.363.298 5.153-1.867 1.413-2.12-44.024-3-90.126-1.72-134.981-.797-47.598.977-95.258.953-142.98.958-47.681.005-95.299.215-142.668 1.666-23.622.723-47.141 1.617-70.804 2.213-23.913.601-47.951.87-71.989.894-48.241.054-96.38-.845-144.392-1.842-5.942-.122-11.884-.249-17.826-.376-4.01-.083-3.968 2.101.208 2.189z"
                fill="#FBA919"
            />
        </svg>
    );
}

export default DoubleCurvesSvg;
