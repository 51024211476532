import React from 'react';
import Link from 'next/link';
import Category from 'models/Category';
import CategoryIcon from 'components/icons/CategoryIcon';

const LinkableCategoryPill = ({ category }: { category: Category }) => {
    return (
        <Link
            href={`/ideje/${category.slug}`}
            className="rounded-full border border-gray-400 flex items-center gap-2 py-1 px-3 text-sm outline-none focus:outline-none transition relative bg-white hover:bg-brand-light flex-shrink-0"
        >
            <CategoryIcon slug={category.slug} size="24px" />
            <span>{category.name}</span>
        </Link>
    );
};

export default LinkableCategoryPill;
