import { FotoIzbor, useIcons, Zvezda } from 'kzv-icons';
import React from 'react';

const categoryIconsMapper = {
    'nagradni-izbor': FotoIzbor
};

function CategoryIcon({ slug, size = '1em' }: { slug: string; size: string | number }) {
    const { interestsIcons } = useIcons();
    let Icon = interestsIcons[slug] ?? categoryIconsMapper[slug];

    if (!Icon) {
        Icon = Zvezda;
    }

    return <Icon style={{ width: size, height: size }} />;
}

export default CategoryIcon;
