import React from 'react';
import { getSanityImageData } from 'content/SanityImage';
import Image from 'next/image';
import Link from 'next/link';
import AuthorModel from 'models/articles/Author';

const Author = ({ author, link = false }: { author: AuthorModel; link?: boolean }) => {
    const authorImage = getSanityImageData({ image: author.image, height: 50, width: 50 });

    return (
        <div className="flex items-center gap-4">
            <div>
                {link && (
                    <Link href={`/namigi/avtor/${author.slug.current}`}>
                        <Image src={authorImage.url} alt={author.name} width={40} height={40} priority />
                    </Link>
                )}
                {!link && <Image src={authorImage.url} alt={author.name} width={40} height={40} priority />}
            </div>
            <div className="leading-tight">
                {link && (
                    <Link href={`/namigi/avtor/${author.slug.current}`}>
                        <strong>{author.name}</strong>
                    </Link>
                )}
                {!link && <strong>{author.name}</strong>}

                {author.articleCount && <p className="text-sm text-secondary">{author.articleCount} namigov</p>}
            </div>
        </div>
    );
};

export default Author;
