import { PuscicaDesno } from 'kzv-icons';
import Button from '../components/buttons/Button';
import SmallCard from 'components/cards/SmallCard';
import UnderlineCurve from 'components/curves/UnderlineCurve';
import Footer from '../components/Footer';
import Link from 'components/links/Link';
import RegionMap from '../components/regions/RegionMap';
import pages from '../config/pages';
import socials from '../config/socials';
import { dehydrate, QueryClient, useQuery } from 'react-query';
import DoubleCurvesSvg from 'components/curves/svg/DoubleCurvesSvg';
import { fetchAreas, fetchCategories, fetchPois } from 'data/fetchers';
import { Mixpanel } from 'utils/Mixpanel';
import { useEffect } from 'react';
import { getClient } from 'content/sanity.server';
import { latestArticlesQuery, latestFeaturedPoiCollectionsQuery } from 'content/queries';
import Article from 'models/articles/Article';
import ArticleCard from 'components/cards/ArticleCard';
import FeaturedPoiCollection from 'components/FeaturedPoiCollection';
import { IPoiCollectionBlock } from 'content/PoiCollectionBlock';
import LinkableCategoryPill from 'components/pills/LinkableCategoryPill';
import AutocompleteSearch from 'components/search/autocomplete/AutocompleteSearch';

export async function getStaticProps({ preview = false }) {
    const queryClient = new QueryClient();

    await queryClient.prefetchQuery('featuredCategories', () =>
        fetchCategories({ filters: [{ key: 'is_featured', value: '1' }] })
    );
    await queryClient.prefetchQuery('areas', () => fetchAreas());
    await queryClient.prefetchQuery('pois', () => fetchPois({}));
    const articles = await getClient(preview).fetch(latestArticlesQuery);
    const latestPoiCollections = await getClient(preview).fetch(latestFeaturedPoiCollectionsQuery);

    return {
        props: {
            dehydratedState: dehydrate(queryClient),
            articles,
            latestPoiCollections,
            revalidate: 60 * 5
        }
    };
}

export default function Home({
    articles,
    latestPoiCollections
}: {
    articles: Article[];
    latestPoiCollections: IPoiCollectionBlock[];
}) {
    const { data: areas } = useQuery('areas', () => fetchAreas());

    const { data: pois } = useQuery('pois', () => fetchPois({}));

    const { data: categories } = useQuery(
        'featuredCategories',
        () => fetchCategories({ filters: [{ key: 'is_featured', value: '1' }] }),
        {
            staleTime: Infinity
        }
    );

    useEffect(() => {
        Mixpanel.trackLinks('.regions a', 'Home region clicked');
    }, []);

    return (
        <div className="single-page home md:pt-8">
            <section id="zacni-avanturo" className="container p-0 mt-4 md:mt-8">
                <div className="text-center">
                    <h1>
                        <UnderlineCurve>Največja zbirka</UnderlineCurve>
                        <br /> idej za izlet po Sloveniji.
                    </h1>
                    <h4 className="mt-2">Naj se pustolovščina prične.</h4>
                </div>

                <div className="relative mt-8 px-6 xl:mt-10 z-10">
                    <div>
                        <AutocompleteSearch placeholder="Kam se odpravljaš?" />
                    </div>

                    <div className="flex mt-8 gap-4 items-center justify-center flex-wrap">
                        {categories?.data.map((category) => (
                            <LinkableCategoryPill category={category} key={category.id} />
                        ))}
                    </div>
                </div>

                <div className="adventure-image -mt-2 md:-mt-2 2xl:-mt-6 max-w-6xl 3xl:max-w-7xl mx-auto">
                    <div className="hidden sm:block max-w-full">
                        <img src="/images/hero/adventure.png" alt="" width={1000} height={400} className="mx-auto" />
                    </div>
                    <div className="sm:hidden max-w-full overflow-hidden h-40">
                        <img src="/images/hero/adventure-mobile.png" alt="" className="object-cover w-full h-full" />
                    </div>
                </div>
            </section>
            {latestPoiCollections.length > 0 && (
                <section id="featured-collection" className="container">
                    <FeaturedPoiCollection collection={latestPoiCollections[0]} />
                </section>
            )}

            {/*<HomeContestSection />*/}

            <section id="slovenske-regije" className="mt-6 md:mt-12 px-6 container">
                <div className="grid grid-cols-12 gap-4 items-center">
                    <div className="col-span-12 md:col-span-4 space-y-4">
                        <h2 className="text-brand uppercase">
                            <UnderlineCurve>Skupaj že {pois?.meta.total} idej</UnderlineCurve>
                            <br className="hidden md:block" /> za izlet
                        </h2>
                        <p className="max-w-xs">
                            Idej nam res ne zmanjka, poleg tega pa jih tedensko dodajamo, da ti lahko ponudimo svežo
                            idejo za vsak vikend.
                        </p>
                        <Button
                            variant="contained"
                            color="secondary"
                            endIcon={<PuscicaDesno strokeWidth={2} />}
                            link={pages.ideas.url}
                            styles="hidden md:inline-flex"
                        >
                            Razišči Slovenijo
                        </Button>
                    </div>
                    <div className="col-span-12 md:col-span-8">
                        <div className="hidden md:block lg:px-12">
                            <RegionMap regions={areas?.data.filter((area) => area.type.key === 'region')} />
                        </div>
                        <div className="md:hidden">
                            <div className="regions grid gap-6 grid-cols-1 sm:grid-cols-2">
                                {areas?.data
                                    .filter((area) => area.type.key === 'region')
                                    .map((region) => (
                                        <SmallCard
                                            key={region.slug}
                                            image={{ url: `/images/regions/${region.slug}.jpg` }}
                                            alt={region.name}
                                            link={`/destinacija/${region.slug}`}
                                        >
                                            <div className="w-full">
                                                <h4 className="leading-tight font-medium text-brand font-serif">
                                                    {region.name}
                                                </h4>
                                            </div>
                                            <p>{region.pois_count} idej</p>
                                        </SmallCard>
                                    ))}
                            </div>

                            <div className="mt-8 text-center">
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    endIcon={<PuscicaDesno strokeWidth={2} />}
                                    link={pages.ideas.url}
                                >
                                    Razišči Slovenijo
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section id="zadnji-namigi" className="container py-16 md:py-32">
                <div className="text-center">
                    <h2 className="text-brand">Zadnji namigi</h2>
                    <p className="max-w-2xl mx-auto mt-3">
                        V namigih najdeš najlepše ideje za izlet po Sloveniji. Upamo, da z namigi dobiš idejo za
                        romantični izlet, enodnevni izlet, izlet z otroci ali pa za večdnevno potepanje po Sloveniji.
                    </p>
                </div>

                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4 md:gap-8 mt-8">
                    {articles.map((article) => (
                        <ArticleCard article={article} key={article._id} />
                    ))}
                </div>

                <div className="text-center mt-14">
                    <Button link="/namigi" endIcon={<PuscicaDesno />} variant="contained" color="secondary">
                        Poglej vse namige
                    </Button>
                </div>
            </section>

            {/* <section
        id="za-naslednji-vikend"
        className="relative bg-gray-200 py-8 mt-24"
      >
       <HorizontalPoiSlider />
      </section> */}
            <section id="mozaik" className="mt-24">
                <div className="container px-0 overflow-hidden">
                    <div className="grid grid-cols-12 gap-6 items-center">
                        <div className="relative col-span-12 lg:col-span-7 xl:col-span-8 lg:order-2 w-[120%] left-[-10%] sm:w-full sm:left-0">
                            <picture>
                                <source media="(min-width: 600px)" srcSet="/images/photo-mosaic.jpg" />
                                <img
                                    className="max-w-full"
                                    src="/images/photo-mosaic-mobile.jpg"
                                    alt="Kam za vikend mozaik"
                                    loading="lazy"
                                />
                            </picture>
                        </div>
                        <div className="col-span-12 lg:col-span-5 xl:col-span-4 space-y-4 px-8">
                            <h2 className="text-xl md:text-3xl text-brand uppercase">
                                Skupaj sestavljamo <br className="hidden md:block" />
                                <UnderlineCurve height={20}>mozaik slovenskih lepot</UnderlineCurve>
                            </h2>
                            <p>
                                V KZV skupnost je vabljen vsakdo, ki uživa v raziskovanju Slovenije. Člani pa s
                                preprostim vnašanjem svojih izletniških idej, informacij in fotografij gradimo največjo
                                zbirko izletniških idej v Sloveniji.
                            </p>
                            <Button
                                variant="contained"
                                color="secondary"
                                endIcon={<PuscicaDesno strokeWidth={2} />}
                                link={pages.about.url}
                            >
                                Več o projektu
                            </Button>
                        </div>
                    </div>
                </div>
            </section>
            <section id="instagram">
                <div className="container mt-24">
                    <div className="grid grid-cols-12 gap-6 items-center">
                        <div className="col-span-12 lg:col-span-5 xl:col-span-4 space-y-6">
                            <img src="/images/instagram-kompas.svg" loading="lazy" className="h-60" alt="" />
                            <h2 className="font-serif uppercase text-brand">Kam na instagram?</h2>
                            <p className="md:max-w-sm">
                                Slovenska mati narava si želi tvojih komentarjev in všečkov. Predvsem pa, da z njo
                                skrbno ravnamo kot njene ljubeče hčere in sinovi. Podpri tako razmišljanje tudi na
                                Instagramu!
                            </p>
                            <UnderlineCurve styles="stroke-dull">
                                <Link
                                    to={socials.instagram}
                                    target="_blank"
                                    styles="font-serif text-2xl hover:underline"
                                >
                                    @kamzavikend.si
                                </Link>
                            </UnderlineCurve>
                        </div>
                        <div className="col-span-12 lg:col-span-7 xl-col-span-8">
                            <div className="hidden lg:block">
                                <DoubleCurvesSvg />
                            </div>
                            <div className="mt-4 lg:mt-12">
                                <Link to={socials.instagram} target="_blank">
                                    <picture>
                                        <source media="(min-width:600px)" srcSet="/images/instagram-photos.jpg" />
                                        <img
                                            src="/images/instagram-photos-mobile.jpg"
                                            alt="Kam za vikend na instagramu"
                                            className="mx-auto"
                                        />
                                    </picture>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section id="footer" className="mt-36">
                <Footer />
            </section>
        </div>
    );
}
