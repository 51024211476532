import React, { ReactNode } from 'react';

import UnderlineCurveSvg from './svg/UnderlineCurveSvg';

const UnderlineCurve = ({
    color,
    children,
    height
}: {
    color?: string;
    children: ReactNode;
    height?: number;
    styles?: string;
}) => {
    return (
        <span className="inline-block relative whitespace-nowrap">
            <span className="relative">{children}</span>
            <span className="inline-block absolute -left-3 -right-3  -bottom-1  z-[-1] pointer-events-none">
                <UnderlineCurveSvg color={color} height={height} />
            </span>
        </span>
    );
};

export default UnderlineCurve;
