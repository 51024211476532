import { useRouter } from 'next/router';
import React from 'react';

import MapSvg from './MapSvg';
import { Mixpanel } from 'utils/Mixpanel';

function RegionMap({ regions }) {
    const router = useRouter();
    const regionClicked = (e) => {
        const regionId = e.currentTarget.id;

        Mixpanel.track('Home region clicked', {
            region: regionId
        });

        router.push(`/ideje/${regionId}`);
    };
    return (
        <div>
            <MapSvg regionsData={regions} handleRegionClick={regionClicked} />
        </div>
    );
}

export default RegionMap;
