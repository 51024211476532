import { getImageDimensions } from '@sanity/asset-utils';
import { urlFor } from 'content/sanity';
import React from 'react';
import Image from 'next/image';
import { SanityImageSource } from '@sanity/asset-utils/src/types';

export const getSanityImageData = ({
    image,
    width,
    height
}: {
    image: SanityImageSource;
    width?: number;
    height?: number;
}) => {
    const { width: imageWidth, height: imageHeight } = getImageDimensions(image);
    const url = urlFor(image)
        .width(width ? width : imageWidth)
        .height(height ? height : imageHeight)
        .quality(100)
        .fit('max')
        .auto('format')
        .url();

    return { url, width: imageWidth, height: imageHeight };
};

interface SanityImageProps {
    value: SanityImageSource & { alt: string };
    isInline?: boolean;
    alt?: string;
    maxHeight?: number;
    className?: string;
}

const SanityImage = ({ value: image, alt = '' }: SanityImageProps) => {
    const { width, height, url } = getSanityImageData({ image, width: 900 });
    return (
        <Image
            src={url}
            alt={image.alt || alt}
            quality={95}
            width={width}
            height={height}
            style={{
                aspectRatio: `${width} / ${height}`
            }}
            className="rounded-3xl"
        />
    );
};

export default SanityImage;
