import React, { ReactNode } from 'react';

import CardBase from 'components/cards/CardBase';
import { PoiThumb } from 'models/Poi';

function SmallCard({
    image,
    alt,
    children,
    link,
    prefetch = true,
    featured = false
}: {
    image: PoiThumb;
    alt: string;
    children: ReactNode;
    link: string;
    prefetch?: boolean;
    featured?: boolean;
}) {
    const prefetchProp = !prefetch ? { prefetch: false } : {};
    return (
        <CardBase
            link={link}
            styles={`basis-full sm:basis-2/5 md:basis-auto relative ${featured ? 'border-2 border-brand' : ''}`}
            {...prefetchProp}>
            {featured && (
                <div className="absolute bottom-2 right-2 rounded-full py-1 px-3 bg-white text-primary text-xs z-10 shadow-md">
                    Sponzorirano
                </div>
            )}
            <div className="flex h-full">
                <div className="flex-shrink-0">
                    <img
                        className="w-28 min-h-[100px] md:w-32 lg:w-36 xl:w-40 h-full object-cover"
                        src={image.url}
                        alt={alt}
                        loading="lazy"
                    />
                </div>
                <div
                    className={`px-3 md:px-4 md:flex flex-col justify-between flex-grow ${
                        featured ? 'pb-12 pt-2' : 'py-2 md:py-3'
                    }`}>
                    {children}
                </div>
            </div>
        </CardBase>
    );
}

export default SmallCard;
