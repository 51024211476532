import React from 'react';
import CardBase from 'components/cards/CardBase';
import Article from 'models/articles/Article';
import { getSanityImageData } from 'content/SanityImage';
import Image from 'next/image';
import Author from 'components/articles/Author';

interface ArticleCardProps {
    article: Article;
    prefetch?: boolean;
}

const ArticleCard = ({ article, prefetch = true }: ArticleCardProps) => {
    const cardImage = getSanityImageData({ image: article.mainImage, height: 250, width: 400 });
    return (
        <CardBase styles="w-full" prefetch={prefetch} link={`/namigi/${article.slug.current}`}>
            <div className="relative h-[180px]">
                <Image src={cardImage.url} alt={article.title} layout={'fill'} objectFit="cover" />
            </div>

            <div className="px-4 pb-4 pt-3 relative z-10 space-y-4">
                <h4 className="leading-tight font-medium text-lg md:text-xl">{article.title}</h4>
                <Author author={article.author} />
            </div>
        </CardBase>
    );
};

export default ArticleCard;
